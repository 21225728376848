<template>
    <div>
      <CModal
        :title="title"
        :color="color"
        :show.sync="customerModal"
      >   
          <CRow>
                <CCol md="12">
                  <CInput class="search"
                    label="Nombre del Cliente: "
                    style="font-size; 18px;"
                    :lazy="false"
                    id="name"
                    name="name"
                    v-model="name"
                    v-on:keyup.enter="setCustomer"
                  />
                </CCol>
              </CRow>
          <template #footer>
            <CButton @click="closeModal" color="secondary">Cancelar</CButton>
            <CButton @click="setCustomer" :color="color">Aceptar</CButton>
          </template>
      </CModal>
    </div>
</template>

<script>
import store from '../../store'

export default {
  name: 'CustomerModal',
  props: {

  },
  components: { },
  data () {
    return {
      customerModal: false,
      title: "Cliente",
      color: "info",
      fields: [
        {key: 'name', label: 'Color'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      name: ''
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal (name) {
      this.customerModal = true;
      this.color = "info";
      this.name = name;
    },
    setCustomer () {
      this.$emit("store", this.name );

      this.customerModal = false;
    },
    closeModal () {
      this.customerModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    
  }
}
</script>