<template>
    <div>
      <CModal
        :title="title"
        :color="color"
        :show.sync="conceptModal"
      >   
          <CRow>
                <CCol md="12">
                  <CInput class="search"
                    label="Buscar: "
                    style="font-size; 18px;"
                    :lazy="false"
                    id="name"
                    name="name"
                    v-model="searching"
                    v-on:keyup.enter="search"
                    placeholder="id, nombre, etc."
                    autocomplete="given-name"
                    invalidFeedback="Este campo es requerido y debe ser mayor a 2 caracteres."
                  />
                </CCol>
              </CRow>
          <template #footer>
            <CButton @click="closeModal" color="secondary">Cerrar Ventana</CButton>
            <CButton @click="search" :color="color">Buscar</CButton>
          </template>
      </CModal>

      <ProductDetailsModal ref="productDetailsModal" @addConcept="addConcept"></ProductDetailsModal>
    </div>
</template>

<script>
import ProductDetailsModal from '../products/ProductDetailsModal.vue'
import ws from '../../services/products';
import store from '../../store'

export default {
  name: 'ConceptModal',
  props: {

  },
  components: { ProductDetailsModal },
  data () {
    return {
      conceptModal: false,
      title: "Agregar Producto",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Color'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      searching: ''
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal () {
      this.conceptModal = true;
      this.color = "info";
      this.searching = "";
      this.title = 'Nuevo Concepto';
    },
    updateModal (item) {
      this.conceptModal = true;
      this.color = "warning";
      this.title = 'Editar Concepto';

      this.form = {
          id: item.id,
          name: item.name
      };
    },
    async search () {
      this.loading();
      let response = await ws.getSearching(this.searching);

      if(response.type == "success"){
        let products = response.data;

        if(products.length > 1){
          this.showProduct(products);
        }
        else{
          if(products.length == 1){
            this.$emit("store", products[0] );
            this.conceptModal = false;
          }
        }

        this.loaded();
      }
    },
    showProduct (products) {
      this.$refs.productDetailsModal.showModal(products);
    },
    addConcept (item) {
      this.$emit("store", item );
      this.conceptModal = false;
    },
    closeModal () {
      this.conceptModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false;
    },
    getEmptyForm () {
      return {
          id: 0,
          name: '',
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    
  }
}
</script>