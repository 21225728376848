<template>
    <div>
      <CModal
        :title="title"
        :color="color"
        :show.sync="paymentModal"
      >   
            <CRow>
              <CCol md="6">
                <div role="group" class="form-group">
                  <label>Fecha:</label>
                  <v-date-picker
                    id="date"
                    name="date"
                    class="date-picker"
                    v-model="form.date"
                    locale="es-MX" size="sm"
                    :maxDate="optionsDate.maxDate"
                  />
                </div>
              </CCol>
              <CCol md="6">
                <CInput
                  id="amount"
                  name="amount"
                  label="Monto:"
                  :lazy="false"
                  v-model="form.amount"
                  placeholder=""
                  invalidFeedback="Este campo es requerido debe ser un valor numérico."
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <div role="group" class="form-group">
                  <CCol md="12" style="text-align: center;">
                    <CButton :color="form.payment_form == 'cash' ? 'info' : 'default'" size="sm" @click="changePaymentForm('cash')" style="margin: 5px;">Efectivo</CButton>
                    <CButton :color="form.payment_form == 'debit' ? 'info' : 'default'" size="sm" @click="changePaymentForm('debit')" style="margin: 5px;">Tarjeta de Débito</CButton>
                    <CButton :color="form.payment_form == 'credit' ? 'info' : 'default'" size="sm" @click="changePaymentForm('credit')" style="margin: 5px;">Tarjeta de Crédito</CButton>
                    <CButton :color="form.payment_form == 'gift' ? 'info' : 'default'" size="sm" @click="changePaymentForm('gift')" style="margin: 5px;">Regalo</CButton>
                  </CCol>
                </div>
              </CCol>
            </CRow>
          <template #footer>
            <CButton @click="closeModal" color="secondary">Cerrar Ventana</CButton>
            <CButton @click="storePaymentProcess" :color="color">Pagar</CButton>
          </template>
      </CModal>
    </div>
</template>

<script>
import ws from '../../services/sales';
import store from '../../store'
import { DatePicker } from 'v-calendar'

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
today.subtract(6, 'hour')
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'PaymentModal',
  props: {

  },
  components: { 
    'v-date-picker': DatePicker
  },
  data () {
    return {
      paymentModal: false,
      title: "Agregar Pago",
      color: "info",
      isEdit: false,
      form: this.getEmptyForm(),
      submitted: false,
      fields: [
        {key: 'name', label: 'Color'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      optionsDate: {
        maxDate: today.toDate(),
        minDate: today2.toDate()
      },
      sale_id: 0,
      balance: 0,
      payment_form: 'cash'
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal (id, balance) {
      this.paymentModal = true;
      this.color = "info";
      this.searching = "";
      this.title = 'Nuevo Pago';

      this.sale_id = id;
      this.balance = balance;

      this.form = this.getEmptyForm()
    },
    updateModal (item) {
      this.paymentModal = true;
      this.color = "warning";
      this.title = 'Editar Pago';

      this.form = {
          id: item.id,
          name: item.name,
          sale_id: this.sale_id
      };
    },
    changePaymentForm (code) {
      this.form.payment_form = code;
    },
    async storePaymentProcess () {
      this.loading();
      let response = await ws.storePayment(this.form);

      if(response.type == "success"){
        this.paymentModal = false;

        this.form = this.getEmptyForm()
        this.submitted = false;

        this.$emit("store");

        this.loaded();
      }

      this.showToast(response.type, response.message);
    },
    closeModal () {
      this.paymentModal = false;

      this.form = this.getEmptyForm()
      this.submitted = false;
    },
    getEmptyForm () {
      return {
          id: 0,
          date: today.toDate(),
          amount: (Math.round(this.balance*100) / 100).toFixed(2),
          sale_id: this.sale_id,
          payment_form: 'cash'
      }
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    
  }
}
</script>