<template>
    <CModal :title="title" color="danger" :show.sync="deleteModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas eliminar quitar el descuento?</p>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="deleteModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="deleteModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="destroy" color="danger">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../store'

export default {
  name: 'DeleteDiscountModal',
  props: {

  },
  data () {
    return {
      deleteModal: false,
      title: "Eliminar Descuento",
    }
  },
  methods: {
    show () {
      this.deleteModal = true;

      this.loaded();
    },
    destroy () {
      this.deleteModal = false;
      this.$emit("delete");
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>