import axios from 'axios'

export default {
    get: () => axios.get('sales'),
    getPreSales: () => axios.get('pre-sales'),
    list: () => axios.get('sales/list'),
    show: (sale_id) => axios.get('sales/'+sale_id+"/show"),
    store: (data) => axios.post('sales/store', data),
    storePreSale: (data) => axios.post('pre-sales/store', data),
    update: (data) => axios.post('sales/update', data),
    cancel: (id) => axios.post('sales/cancel', {id: id}),
    downloadPDF: (id) => axios.get('sales/'+id+'/pdf').then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    storePayment: (data) => axios.post('payments/store', data),
    getPayments: (sale_id) => axios.get('sales/'+sale_id+"/payments"),
    deletePayment: (payment_id) => axios.post('payments/delete', {id: payment_id}),
}