<template>
    <CModal :title="title" :color="color" :show.sync="discountModal">   
        <CRow>
          <CCol md="6">
            <CSelect
              label="Porcentaje"
              id="rate"
              name="rate"
              :value.sync="rate"
              :options="[{label: '5%', value: 5}, {label: '10%', value: 10}, {label: '15%', value: 15}, {label: '20%', value: 20}, {label: '25%', value: 25}, {label: '30%', value: 30}]"
              @change="changeDiscount"
            />
          </CCol>
          <CCol md="6">
            <CInput
              label="Monto: "
              style="font-size; 18px;"
              :lazy="false"
              id="discount"
              name="discount"
              v-model="discount"
            />
          </CCol>
        </CRow>
        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="discountModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="discountModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="apply" :color="color">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../store'

export default {
  name: 'DiscountModal',
  props: {

  },
  data () {
    return {
      discountModal: false,
      title: "Aplicar Descuento",
      id: 0,
      name: 'Descuento',
      color: "success",
      rate: 0,
      discount: 0,
      total: 0
    }
  },
  methods: {
    show (total, rate, color) {
      this.discountModal = true;
      this.rate = rate;
      this.color = color;

      this.total = total;

      this.discount = parseFloat(total * this.rate / 100).toFixed(2);

      this.loaded();
    },
    changeDiscount () {
      this.discount = parseFloat(this.total * this.rate / 100).toFixed(2);
    },
    apply () {
      this.$emit("apply", this.rate, this.discount );
      this.discountModal = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>