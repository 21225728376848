<template>
    <CModal
      :title="title"
      :color="color"
      size="lg"
      :show.sync="showProductModal"
    >   
        <CRow>
            <CCol col="12" style="background-color: #EEE; padding-top: 20px;">
              <CRow>
                <CCol :col="is_mobile ? 12 : (items.length == 1 ? 12 : 6)" v-for="item in items">
                  <CCard style="width: 100%; ">
                    <CCardHeader>
                      <span v-if="item.thumb_image" style="float: right; font-size: 14px;">ID: {{item.id}}</span><br>
                      <img v-if="item.thumb_image" style="width: 50%;" :src='item.image'>
                      <span v-if="!item.thumb_image">NO IMAGE</span>
                      <span v-if="!item.thumb_image" style="float: right; font-size: 14px;">ID: {{item.id}}</span><br>
                    </CCardHeader>
                    <CCardBody>
                      <CCardTitle>
                        {{item.name}}
                      </CCardTitle>
                      <CCardText>
                        <i v-if="item.category_name && item.type_name">{{item.category_name}} / {{item.type_name}}</i>
                        <span v-if="item.sizes_label"><br />Tallas: {{item.sizes_label}}</span>
                        <span style="float: right;">$ {{item.price | currency}}</span>
                        <br />
                        <br />
                        <CButton @click="addConcept(item)" color="info" style="float: right;" size="sm">Agregar</CButton>
                      </CCardText>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cerrar Ventana</CButton>
        </template>
    </CModal>
</template>

<script>

export default {
  name: 'ProductDetailsModal',
  components: {
  },
  props: {
    
  },
  data () {
    return {
      showProductModal: false,
      title: "Información del Producto",
      color: "info",
      items: false
    }
  },
  mounted: async function() {
    var w = window.innerWidth;
    var h = window.innerHeight;

    if(w < 800){
      this.is_mobile = true;
    }
    else{
      this.is_mobile = false;
    }
  },
  methods: {
    async showModal (items) {
      this.items = items;
      this.showProductModal = true;
    },
    async addConcept (item) {
      this.$emit("addConcept", item );
      this.showProductModal = false;
    },
    closeModal () {
      this.showProductModal = false;
      this.items = false;
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>