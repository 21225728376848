<template>
    <CModal :title="title" color="danger" :show.sync="deleteModal">   
        <p style="font-weight: bold;">¿Estás seguro que deseas eliminar el pago seleccionado?</p>
        <br>
        El registro <b>{{name}}</b> será eliminado de este apartado / venta.

        <template #header>
          <h5 class="modal-title">{{title}}</h5>
          <CButtonClose @click="deleteModal = false" class="text-white"/>
        </template>
        <template #footer>
          <CButton @click="deleteModal = false" color="secondary">Cancelar</CButton>
          <CButton @click="destroy" color="danger">Aceptar</CButton>
        </template>
    </CModal>
</template>

<script>
import store from '../../store'
import ws from '../../services/sales';

export default {
  name: 'DeletePaymentModal',
  props: {

  },
  data () {
    return {
      deleteModal: false,
      title: "Eliminar Registro",
      id: 0,
      name: 'Registro',
      item: false,
      index: -1
    }
  },
  methods: {
    show (title, item, index) {
      this.deleteModal = true;
      this.title = title;
      this.id = item.id;
      this.name = item.name;
      this.item = item;
      this.index = index;

      this.loaded();
    },
    async destroy () {
      this.deleteModal = false;

      let response = await ws.deletePayment(this.id);

      if(response.type == "success"){
        this.deleteModal = false;

        this.loaded();
      }

      this.showToast(response.type, response.message);

      this.$emit("delete", this.item );
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>