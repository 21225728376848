<template>
  <div>
    <span style="float:right;" size="sm" v-if="!is_new">{{date}}</span>
    <h2>{{id > 0 ? (is_paid ? 'Venta #'+id : 'Apartado #'+id) : caption}}</h2><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          <CButton color="dark" size="sm" @click="is_new && changeNameModal()" style="float: left; ">{{customer_name}}</CButton>
          <CButton color="info" size="sm" @click="is_new && changePaymentFormModal()" style="float: left; margin-left: 5px;">{{payment_form_name}}</CButton>      
          <div class="card-header-actions">
            <CButton color="success" size="sm" v-if="is_new" @click="addDiscountModal(10, 'success')">Descuento</CButton>
            <CButton color="info" size="sm" v-if="is_new" @click="addConceptModal()">Nuevo Concepto</CButton>
            <CButton color="info" size="sm" v-if="!is_new && !is_paid && total - paid > 0" @click="addPaymentModal(id)">Nuevo Pago</CButton>
            <span v-if="!is_new && is_paid">PAGADO</span>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CDataTable v-if="render"
          :hover="hover"
          :striped="striped"
          :border="border" 
          :small="small" 
          :fixed="fixed"
          :items="items"
          :fields="is_new ? fields : fields_no_actions"
          :dark="dark" 
          addTableClasses="no-margin"
        >
          <template #image="{item}"><td v-if="item.visible" class="w100 text-center"><img style="width: 100px;" :src='item.thumb_image'></td></template>
          <template #id="{item}"><td v-if="item.visible" class="w100 text-center">{{item.id}}</td></template>
          <template #price="{item}"><td v-if="item.visible" class="w100 text-right">$ {{item.price | currency}}</td></template>
          <template #name="{item}"><td v-if="item.visible" class="text-left">{{item.name}}<br /><i v-if="item.category_name && item.type_name">{{item.category_name}} / {{item.type_name}}</i><span v-if="item.sizes_label"><br /><br />Tallas: {{item.sizes_label}}</span></td></template>
          <template #size="{item, index}">
            <td v-if="item.visible" class="w150 text-center">
              <CSelect
                v-if="is_new && item.sizeOptions.length > 1"
                @change="selectSize($event, item, index)"
                :options="item.sizeOptions"
                />
              <CSelect
                v-if="is_new && item.sizeOptions.length == 1"
                disabled
                @change="selectSize($event, item, index)"
                :options="['']"
              />
              <span v-if="!is_new">{{getSizeName(item, item.size)}}</span> 
            </td>
          </template>
          <template #actions="{item, index}">
            <td class="table_actions" v-if="is_new && item.visible">
              <CButton color="danger" size="sm" @click="deleteModal(item, index)"><CIcon name="cil-trash"/></CButton>
            </td>
          </template> 
        </CDataTable>
        <CDataTable
          addTableClasses="no-margin"
          :header="false"
          :hover="hover"
          :striped="striped"
          :border="border"
          :small="small"
          :fixed="fixed"
          :fields="totals_fields"
          :items="totals_items" 
        >
          <template #labels="{item}"><th class="w150 text-right" style="text-align: right !important; vertical-align: middle;">{{ item.labels }}</th></template>
          <template #totals="{item, index}">
            <td class="w100 text-right">
              $ {{ (index == 0 ? subtotal : (index == 1 ? discounts : ((index == 2) ? subtotal - discounts : (index == 1 || (index == 3) ? transfered_taxes : (index == 2 || index == 4 ? withheld_taxes : total))))) | currency }}
            </td>
          </template>
          <template #letters="{item, index}"><td :rowspan="discounts > 0 ? 6 : 4" class="text-center " v-if="index == 0"><br>{{letters}}<span style="vertical-align: bottom;" v-if="comments != ''"><hr v-if="letters != ''"><div style="width: 100%; text-align: center; font-size: 28px;"><b></b><pre>{{comments}}</pre></div></span></td></template>
          <template #actions="{item, index}">
            <td class="table_actions b2" v-if="is_new">
              <CButton color="danger" size="sm" @click="deleteDiscountModal()" v-if="discounts > 0 && index == 1"><CIcon name="cil-trash"/></CButton>
            </td>
          </template>
        </CDataTable>
        <br />
        <CDataTable v-if="render && paid > 0"
          :hover="hover"
          :striped="striped"
          :border="border" 
          :small="small" 
          :fixed="fixed"
          :items="payments"
          :fields="payment_fields"
          :dark="dark" 
          addTableClasses="no-margin"
        >
          <template #date="{item}"><td class="w200 text-center">{{item.date | date}}</td></template>
          <template #payment_form="{item}"><td class="w200 text-center">{{item.payment_form == 'cash' ? 'Efectivo' : (item.payment_form == 'debito' ? 'Tarjeta de Débito' : (item.payment_form == 'credit' ? 'Tarjeta de Crédito' : 'Regalo'))}}</td></template>
          <template #description="{item}"><td class="text-center">Pago registrado bajo la modalidad de Sistema de Apartado</td></template>
          <template #amount="{item}"><td class="w200 text-right">$ {{item.amount | currency}}</td></template>
          <template #actions="{item, index}">
            <td class="table_actions">
              <CButton color="danger" size="sm" @click="deletePaymentModal(item, index)"><CIcon name="cil-trash"/></CButton>
            </td>
          </template> 
        </CDataTable>
      </CCardBody>
      <PaymentFormModal ref="paymentFormModal" @store="changePaymentForm"></PaymentFormModal>
      <CustomerModal ref="customerModal" @store="changeName"></CustomerModal>
      <ConceptModal ref="conceptModal" @store="storeProcess"></ConceptModal>
      <DeleteModal ref="deleteModal" @delete="deleteProcess"></DeleteModal>
      <DeletePaymentModal ref="deletePaymentModal" @delete="deletePaymentProcess"></DeletePaymentModal>
      <CancelModal ref="cancelModal" @cancel="cancelSale"></CancelModal>
      <DiscountModal ref="discountModal" @apply="addDiscount"></DiscountModal>
      <DeleteDiscountModal ref="deleteDiscountModal" @delete="deleteDiscount"></DeleteDiscountModal>
      <PaymentModal ref="paymentModal" @store="storePaymentProcess"></PaymentModal>
    </CCard>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody class="text-left">
            <CButton class="btn btn-outline-danger" size="sm" @click="cancelSaleModal()" v-if="!is_new && !is_canceled">Cancelar</CButton>
            <CButton class="btn btn-outline-dark" size="sm" @click="clearSale()" v-if="is_new && items.length > 0">Limpiar</CButton>
            <div class="card-header-actions">
              <CButton color="danger" size="sm" @click="downloadPDF()" v-if="!is_new">Imprimir</CButton>
              <CButton color="info" size="sm" @click="storeSale()" v-if="caption == 'Nueva Venta' && is_new && items.length > 0">Registrar Venta</CButton>
              <CButton color="info" size="sm" @click="storePreSale()" v-if="caption == 'Nuevo Apartado' && is_new && items.length > 0">Registrar Apartado</CButton>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import PaymentModal from './PaymentModal.vue'
import ConceptModal from './ConceptModal.vue'
import DeleteModal from './DeleteModal.vue'
import DeletePaymentModal from './DeletePaymentModal.vue'
import DiscountModal from './DiscountModal.vue'
import DeleteDiscountModal from './DeleteDiscountModal.vue'
import CustomerModal from './CustomerModal.vue'
import PaymentFormModal from './PaymentFormModal.vue'
import CancelModal from './CancelModal.vue'
import store from '../../store'
import sales from '../../services/sales';

import moment from 'moment';
import moment_timezone from 'moment-timezone'
import { computed } from 'vue'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss"));

export default {
  name: 'ConceptsTable',
  components: { ConceptModal, DeleteModal, DiscountModal, DeleteDiscountModal, CustomerModal, PaymentFormModal, CancelModal, PaymentModal, DeletePaymentModal },
  props: {
    permissions: Array,
    caption: {
      type: String,
      default: 'Table'
    },
    letters: {
        type: String,
        default: ''
      },
    fields: {
      type: Array,
      default () {
        return [
          {key: 'id', label: 'Id'},
          {key: 'image', label: 'Imagen'},
          {key: 'name', label: 'Producto'},
          {key: 'size', label: 'Talla'},
          {key: 'price', label: 'Precio'},
          {key: 'actions', label: '', class: 'actions' },
        ]
      }
    },
    payment_fields: {
      type: Array,
      default () {
        return [
          {key: 'date', label: 'Fecha de Pago'},
          {key: 'description', label: 'Descripcion'},
          {key: 'payment_form', label: 'Forma de Pago'},
          {key: 'amount', label: 'Monto del Pago'},
          {key: 'actions', label: '', class: 'actions' },
        ]
      }
    },
    fields_no_actions: {
      type: Array,
      default () {
        return [
          {key: 'id', label: 'Id'},
          {key: 'image', label: 'Imagen'},
          {key: 'name', label: 'Producto'},
          {key: 'size', label: 'Talla'},
          {key: 'price', label: 'Precio'},
        ]
      }
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  data() {
    return {
      toasts: [],
      is_new: true,
      totals_fields: [
        {key: 'letters', label: ''},
        {key: 'labels', label: ''},
        {key: 'totals', label: ''},
        {key: 'actions', label: ''},
        
      ],
      totals_items: [
          {letters: '', labels: 'Subtotal', totals: '0'},
          {letters: '', labels: 'Descuento', totals: '0'},
          // {letters: '', labels: 'Subtotal', totals: '0'},
          // {letters: '', labels: 'Impuestos Trasladados', totals: '0'},
          // {letters: '', labels: 'Impuestos Retenidos', totals: '0'},
          {letters: '', labels: 'Total', totals: '0'}
      ],
      totals_items_without_discounts: [
        {letters: '', labels: 'Subtotal', totals: '0'},
        // {letters: '', labels: 'Impuestos Trasladados', totals: '0'},
        // {letters: '', labels: 'Impuestos Retenidos', totals: '0'},
        {letters: '', labels: 'Total', totals: '0'}
      ],
      id: 0,
      items: [],
      subtotal: 0,
      discount_rate: 0,
      discounts: 0,
      transfered_taxes: 0,
      withheld_taxes: 0,
      total: 0,
      paid: 0,
      comments: '',
      position: 0,
      render: true,
      selections: [],
      customer_name: "Público en General",
      payment_form: 'cash',
      payment_form_name: 'Efectivo',
      date: '',
      is_canceled: 0,
      is_paid: 1,
      payments: []
    }
  },
  mounted: async function() {
    this.id = await this.$route.params.id;

    if(this.id > 0){
      this.is_new = false;
      this.loadSale();

      let response = await sales.getPayments(this.id);

      if(response.type == "success"){
        let data = response.data;
        this.payments = data.payments;
        this.paid = data.paid;
      }
    }

    this.updateTotals();
  },
  methods: {
    changeNameModal () {
      this.$refs.customerModal.openModal(this.customer_name);
    },
    changeName (name) {
      this.customer_name = name;
    },
    changePaymentFormModal () {
      this.$refs.paymentFormModal.openModal(this.payment_form);
    },
    changePaymentForm (code, name) {
      this.payment_form = code;
      this.payment_form_name = name;
    },
    async storePaymentProcess () {
      let response = await sales.getPayments(this.id);

      if(response.type == "success"){
        let data = response.data;
        this.payments = data.payments;
        this.paid = data.paid;
        this.is_paid = data.is_paid;
      }
    },
    addConceptModal () {
      this.$refs.conceptModal.openModal();
    },
    addPaymentModal (id) {
      this.$refs.paymentModal.openModal(id, this.total - this.paid);
    },
    updateModal (item) {
      this.$refs.conceptModal.updateModal(item);
    },
    deleteModal (item, index) {
      this.$refs.deleteModal.show("Eliminar Concepto", item, index);
    },
    deletePaymentModal (item, index) {
      this.$refs.deletePaymentModal.show("Eliminar Pago", item, index);
    },
    cancelSaleModal () {
      this.$refs.cancelModal.show(this.id);
    },
    async cancelSale (sale_id) {
      this.loading();

      let response = await sales.cancel(sale_id);

      if(response.type == "success"){
        this.is_canceled = 1;

        if(this.is_canceled){
          this.comments = "VENTA CANCELADA"
        }
      }

      this.showToast(response.type, response.message); 

      this.loaded();
    },
    async selectSize(event, item, index) {
      item.size = event.target.value;

      this.items.push(false);
    },
    async storeProcess(item) {
      this.subtotal += parseFloat(item.price);
      
      if(this.discount_rate > 0){
        this.discounts += parseFloat(item.price * (this.discount_rate / 100));
        this.total += parseFloat(item.price * (1 - this.discount_rate / 100));
      }
      else{
        this.total = this.subtotal;
      }

      item.sizeOptions = await this.$parseSelectOptions(item.sizes, "id", "name");
      item.size = 0;
      item.visible = true;

      item.position = await this.position++;

      this.items.push(item);
    },
    async addDiscount(rate, discount) {
      let dis1 = parseFloat(this.total * rate / 100).toFixed(2);
      let dis2 = parseFloat(discount).toFixed(2);

      if(dis1 != dis2){
        this.discount_rate = parseFloat(dis2 * 100 / this.total).toFixed(4);
        this.discounts = dis2;
      }
      else{
        this.discount_rate = rate;
        this.discounts = dis1;
      }
      this.comments = parseFloat(this.discount_rate).toFixed(2)+"% DE DESCUENTO";

      this.updateTotals();
    },
    async addDiscountModal(rate, color) {
      this.discount_rate = 0;
      this.updateTotals();

      this.$refs.discountModal.show(this.total, rate, color);
    },
    async deleteDiscount(rate) {
      this.discount_rate = 0;

      this.comments = "";

      this.updateTotals();
    },
    async deleteDiscountModal(rate) {
      this.$refs.deleteDiscountModal.show();
    },
    async deleteProcess(item) {
      item.visible = false; 
      this.render = false;
      this.updateTotals();
      this.render = true;

      this.items.push(false);
    },
    async deletePaymentProcess(item) {
      let response = await sales.getPayments(this.id);

      if(response.type == "success"){
        let data = response.data;
        this.payments = data.payments;
        this.paid = data.paid;
        this.is_paid = data.is_paid;
      }
    },
    updateTotals(){
      this.subtotal = 0;
      this.discounts = 0;
      this.total = 0;

      this.items.forEach(item => {
        if(item.visible){
          this.subtotal += parseFloat(item.price);
      
          if(this.discount_rate > 0){
            this.discounts += parseFloat(item.price * (this.discount_rate / 100));
            this.total += parseFloat(item.price * (1 - this.discount_rate / 100));
          }
          else{
            this.total = this.subtotal;
          }
        }
      });
    },
    async storeSale () {
      this.loading();

      //Validar tallas
      let flag_sizes = true;
      this.items.forEach(item => {
        if(item.visible){
          if(item.sizes.length > 0 && item.size == 0){
            this.showToast("error", "Debes seleccionar las tallas de los productos.");
            flag_sizes = false;
          }
        }
      });

      if(!flag_sizes){
        this.loaded();

        return;
      }

      let data = {
        name: this.customer_name,
        discount_rate: this.discount_rate,
        discount: this.discounts,
        subtotal: this.subtotal,
        total: this.total,
        concepts: this.items,
        payment_form: this.payment_form,
        is_paid: 1
      }

      let response = await sales.store(data);

      if(response.type == "success"){
        let data_response = response.data;
        this.is_new = false;
        this.id = data_response.id;

        this.payments = data_response.payments;
        this.paid = data_response.paid;
        this.is_paid = data_response.is_paid;
      }

      this.showToast(response.type, response.message); 
      
      this.loaded();
    },
    async storePreSale () {
      this.loading();

      //Validar tallas
      let flag_sizes = true;
      this.items.forEach(item => {
        if(item.visible){
          if(item.sizes.length > 0 && item.size == 0){
            this.showToast("error", "Debes seleccionar las tallas de los productos.");
            flag_sizes = false;
          }
        }
      });

      if(!flag_sizes){
        this.loaded();

        return;
      }

      let data = {
        name: this.customer_name,
        discount_rate: this.discount_rate,
        discount: this.discounts,
        subtotal: this.subtotal,
        total: this.total,
        concepts: this.items,
        payment_form: this.payment_form,
        is_paid: 0
      }

      let response = await sales.storePreSale(data);

      if(response.type == "success"){
        let data_response = response.data;
        this.is_new = false;
        this.id = data_response.sale.id;

        this.payments = data_response.payments;
        this.paid = data_response.paid;
        this.is_paid = 0;
      }

      this.showToast(response.type, response.message); 
      
      this.loaded();
    },
    async loadSale(){
      let response = await sales.show(this.id);

      if(response.type == "success"){
        let data = response.data;

        this.customer_name = data.customer_name;
        this.payment_form = data.payment_form;
        this.payment_form_name = data.payment_form_name;

        this.items = data.concepts;

        this.subtotal = data.subtotal;
        this.discounts = data.discount;
        this.total = data.total;
        this.paid = data.paid;

        this.discount_rate = (data.subtotal - data.total) / data.subtotal * 100;

        if(this.discounts > 0){
          let rate = this.discounts * 100 / this.subtotal;
          this.comments = parseFloat(rate).toFixed(2)+"% DE DESCUENTO";
        }

        this.is_canceled = !data.is_active;
        this.is_paid = data.is_paid;

        if(this.is_canceled){
          this.comments = "VENTA CANCELADA"
        }

        this.date = moment(data.date, 'YYYY-MM-DD').format("DD/MM/YYYY");

        this.updateTotals();
      }
    },
    clearSale () {
      location.reload()
    },
    getSizeName (item, size_id) {
      let size_name = '-';
      if(item.sizes !== undefined && item.sizes !== null && item.sizes.length > 0){
        item.sizes.forEach(size => {
          if(size.id == size_id){
            size_name = size.name;
          }
        });
      }
      else{
        size_name = size_id ? size_id : '-'
      }

      return size_name;
    },
    async downloadPDF(){
      sales.downloadPDF(this.id);
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
