<template>
    <div>
      <CModal
        :title="title"
        :color="color"
        :show.sync="paymentFormModal"
      >   
          <CRow>
            <CCol md="12" style="text-align: center;">
              <CButton :color="payment_form == 'cash' ? 'info' : 'default'" size="sm" @click="changePaymentForm('cash')" style="margin: 5px;">Efectivo</CButton>
              <CButton :color="payment_form == 'debit' ? 'info' : 'default'" size="sm" @click="changePaymentForm('debit')" style="margin: 5px;">Tarjeta de Débito</CButton>
              <CButton :color="payment_form == 'credit' ? 'info' : 'default'" size="sm" @click="changePaymentForm('credit')" style="margin: 5px;">Tarjeta de Crédito</CButton>
              <CButton :color="payment_form == 'gift' ? 'info' : 'default'" size="sm" @click="changePaymentForm('gift')" style="margin: 5px;">Regalo</CButton>
            </CCol>
          </CRow>
          <template #footer>
            <CButton @click="closeModal" color="secondary">Cerrar Ventana</CButton>
          </template>
      </CModal>
      </CModal>
    </div>
</template>

<script>
import store from '../../store'

export default {
  name: 'PaymentFormModal',
  props: {

  },
  components: { },
  data () {
    return {
      paymentFormModal: false,
      title: "Forma de Pago",
      color: "info",
      fields: [
        {key: 'name', label: 'Color'},
        {key: 'actions', label: '', class: 'actions' }
      ],
      payment_form: 'cash'
    }
  },
  mounted: async function() {
    
  },
  methods: {
    openModal (payment_form) {
      this.paymentFormModal = true;
      this.color = "info";
      this.payment_form = payment_form;
    },
    changePaymentForm (code) {
      let name = code == "cash" ? "Efectivo" : (code == "debit" ? "Tarjeta de Débito" : (code == "credit" ? "Tarjeta de Crédito" : "Regalo"));
      this.$emit("store", code, name);

      this.paymentFormModal = false;
    },
    closeModal () {
      this.paymentFormModal = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  },
  computed: {
    
  }
}
</script>